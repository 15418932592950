<template>
  <v-card :height="$printTag.size(tagSettings.height)" :width="$printTag.size(tagSettings.width)"
    class="container--fluid card tag" outlined>
    <div style="word-break: break-word" class="pa-2 texto ">
      <b>{{ product.code }} - {{ product.name }}</b>
      <div class="d-flex flex-column">
        <span>
          À Vista R$
          {{ $format.decimal(product.product_variants[0].price_cash) }}
        </span>
        <small>
          À Prazo R$
          {{ $format.decimal(product.product_variants[0].price_forward) }}
        </small>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
    tagSettings: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ratio() {
      return this.tagSettings.width / this.tagSettings.height;
    },
  },
};
</script>

<style scoped>
.texto {
  font-size: 10px;
  letter-spacing: 1px;
}
.tag{
  margin-top: 3px;
}
</style>
